import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { DiscussionEmbed } from 'disqus-react';
import Layout from '../components/layout';
import BlogPosting from '../components/structured-data/blog-posting';
import RelatedPosts from '../components/related-posts';
import DateCategories from '../components/date-categories';
import blogPostURLProcess from '../utils/blog-post-url-process';
import SocialShare from '../components/social-share';
import featuredImageDefault from '../images/pantaley.com.png';

import '../styles/post.scss';

const BlogPost = ({ data }) => {
  const post = data.markdownRemark;
  const replatedPosts = data.allMarkdownRemark.edges
    .filter((relatedPost) => {
      const postCat = post.frontmatter.categories;
      const relatedCat = relatedPost.node.frontmatter.categories;

      const postSlug = post.fields.slug;
      const relatedSlug = relatedPost.node.fields.slug;

      if (postSlug === relatedSlug) {
        return false;
      }

      return relatedCat.find((cat) => postCat.includes(cat)) ? true : false;
    })
    .slice(0, 4);

  const disqusShortname = 'pantaley';
  const siteURL = data.site.siteMetadata.siteUrl;
  const featuredImage = post.frontmatter.featuredImage
    ? post.frontmatter.featuredImage.publicURL
    : featuredImageDefault;
  const socialSharedData = {
    url: `${siteURL}${blogPostURLProcess(post.fields.slug)}`,
    media: `${siteURL}${featuredImage}`,
    title: post.frontmatter.title
  };
  const disqusConfig = {
    url: `${data.site.siteMetadata.siteUrl}${blogPostURLProcess(post.fields.slug)}`,
    identifier: blogPostURLProcess(post.fields.slug),
    title: post.frontmatter.title
  };

  return (
    <Fragment>
      <Layout postData={post.frontmatter} smallHeader={true}>
        <h1>{post.frontmatter.title}</h1>
        <DateCategories data={post.frontmatter} />
        <SocialShare data={socialSharedData} />
        <article id="post">
          {post.frontmatter.featuredImage ? (
            <Img
              fluid={post.frontmatter.featuredImage.childImageSharp.fluid}
              alt={post.frontmatter.title}
              loading="eager"
              fadeIn={false}
            />
          ) : null}

          <div dangerouslySetInnerHTML={{ __html: post.html }} />
        </article>
        <DiscussionEmbed shortname={disqusShortname} config={disqusConfig} />
        <RelatedPosts replatedPosts={replatedPosts} />
      </Layout>
      <BlogPosting postData={post} />
    </Fragment>
  );
};

export default BlogPost;

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        excerpt
        date
        categories
        featuredImage {
          publicURL
          childImageSharp {
            fluid(maxWidth: 600, maxHeight: 315, toFormat: WEBP, quality: 70) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      fields {
        slug
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      ...markdownRemarkEdge
    }
  }
`;
