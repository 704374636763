import React from 'react';
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  PinterestShareButton,
  PinterestIcon,
  EmailShareButton,
  EmailIcon,
  RedditShareButton,
  RedditIcon
} from 'react-share';

import '../styles/social-share.scss';

const SocialShare = (props) => {
  const iconProps = {
    size: 32,
    bgStyle: { fill: '#333' }
  };
  const buttonDefaults = {
    url: props.data.url
  };

  return (
    <div id="social-icons">
      <FacebookShareButton {...buttonDefaults} quote={props.data.title}>
        <FacebookIcon {...iconProps} />
      </FacebookShareButton>
      <LinkedinShareButton {...buttonDefaults} title={props.data.title}>
        <LinkedinIcon {...iconProps} />
      </LinkedinShareButton>
      <PinterestShareButton {...buttonDefaults} media={props.data.media}>
        <PinterestIcon {...iconProps} />
      </PinterestShareButton>
      <RedditShareButton {...buttonDefaults} title={props.data.title}>
        <RedditIcon {...iconProps} />
      </RedditShareButton>
      <TwitterShareButton {...buttonDefaults} title={props.data.title}>
        <TwitterIcon {...iconProps} />
      </TwitterShareButton>
      <EmailShareButton {...buttonDefaults} subject={props.data.title}>
        <EmailIcon {...iconProps} />
      </EmailShareButton>
    </div>
  );
};

export default SocialShare;
