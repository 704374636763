import React, { Fragment } from 'react';
import BodyDrawer from '../components/body-drawer';
import PostPreview from '../components/post-preview';

const RelatedPosts = ({ replatedPosts }) => {
  return (
    <Fragment>
      <h2>Related articles</h2>
      <BodyDrawer>
        {replatedPosts.map(({ node }) => {
          return <PostPreview key={node.id} data={node} />;
        })}
      </BodyDrawer>
    </Fragment>
  );
};

export default RelatedPosts;
