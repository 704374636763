import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import authorImage from '../../images/Pantaley-Stoyanov.jpg';
import featuredImage from '../../images/pantaley.com.png';
import blogPostURLProcess from '../../utils/blog-post-url-process';

const BlogPosting = ({ postData }) => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          excerpt
          siteUrl
        }
      }
    }
  `);
  const json = {
    '@context': 'http://schema.org',
    '@type': 'BlogPosting',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': `${site.siteMetadata.siteUrl}${blogPostURLProcess(postData.fields.slug)}`
    },
    headline: postData.frontmatter.title,
    image: {
      '@type': 'ImageObject',
      url: `${site.siteMetadata.siteUrl}${
        postData.frontmatter.featuredImage
          ? postData.frontmatter.featuredImage.publicURL
          : `${site.siteMetadata.siteUrl}${featuredImage}`
      }`,
      height: 365,
      width: 696
    },
    editor: 'Pantaley Stoyanov',
    keywords: postData.frontmatter.categories.join(),
    url: `${site.siteMetadata.siteUrl}${blogPostURLProcess(postData.fields.slug)}`,
    datePublished: postData.frontmatter.date,
    dateCreated: postData.frontmatter.date,
    dateModified: postData.frontmatter.date,
    description: postData.frontmatter.excerpt,
    author: {
      '@type': 'Person',
      name: 'Pantaley Stoyanov',
      image: `${site.siteMetadata.siteUrl}${authorImage}`
    },
    publisher: {
      '@type': 'Organization',
      name: 'Pantaley Stoyanov',
      logo: {
        '@type': 'ImageObject',
        url: `${site.siteMetadata.siteUrl}${authorImage}`,
        height: 60,
        width: 60
      }
    }
  };
  return <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(json) }} />;
};

export default BlogPosting;
